import { Formik, useFormik } from 'formik'
import React, { useEffect, useMemo, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactLoading from "react-loading";

import { Link } from 'react-router-dom'
import './auth.css'
import { NavBarScreen } from '../navBar/NavBarScreen';
import { FooterScreen } from '../footer/FooterScreen';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'universal-cookie/es6';
import { useForm } from '../../hooks/useForm';
import { chekingAutentication } from '../../store/auth/thunks';
import { requestRole } from '../../functions/requestRole';
import { requestEnterprise } from '../../functions/requestEnterprise';
import { PreLoader } from '../initialsPages/PreLoader';


const cookies = new Cookies();
// const rol = cookies.get('rol');
var rol = '';
// var enterprise = {};
const islogged = cookies.get('log');


export const LoginScreen = () => {
    const [enterprise, setenterprise] = useState()

    const loadUser = async () => {
        rol = await requestRole(cookies.get('uid'))
        if (rol === '3f7be9c1-997b-42f1-b0b5-58da7de1f37c') {
            window.location.href = "/administrativo";
        } else {
            if (rol === 'a930c4ac-462c-4d05-bacd-a2691ae6e0cc') {
                window.location.href = "/guide";
            } else {
                if (rol === '9acf6630-8ab4-41bb-a5e4-477221ef820d') {
                    window.location.href = "/bodeguero";
                } else {
                    if (rol === 'client') {
                        window.location.href = "/cliente";
                    } else {
                        if (rol === 'c642c1be-81a7-4bfb-bc85-c04238b93857') {
                            window.location.href = "/subscriber";
                        } else {

                        }
                    }
                }

            }
        }
    }

    if (cookies.get('token') && cookies.get('uid')) {
        loadUser();
    } else {
        console.log('no está logeado')
    }



    const { status, errorMessage } = useSelector(state => state.auth);
    const isAuthenticate = useMemo(() => status === 'checking', [status]);


    const { email, password, onInputChange } = useForm({
        email: '',
        password: ''
    })
    const dispatch = useDispatch();
    const loading = useSelector(state => state.ui);


    const onSubmit = (event) => {
        event.preventDefault();
        dispatch(chekingAutentication(email, password));
    }

    // if (islogged) {
    //     if (rol === "admin") {
    //         window.location.href = "/administrativo";
    //     } else {
    //         if (rol === "guide") {
    //             window.location.href = "/administrativo";
    //         }
    //     }
    // } else {

    const loadEnterprise = async () => {

        var enterprise = (await requestEnterprise());
        setenterprise(enterprise);
        // console.log(enterprise.software_type_id);
        // console.log("***********")
    }

    useEffect(() => {
        loadEnterprise();
    }, [])

    if (!enterprise) {
        // return (<h3>Cargando Datos...</h3>)
        return (<PreLoader />)
    }

    return (
        <>
            {console.log("aaaa")}
            {console.log(enterprise.img)}
            {
                (enterprise.software_type_id) ?
                    (enterprise.software_type_id == 1) ? <NavBarScreen /> : <></>
                    : <>no es</>
            }

            <div className='fondo-register' style={{
                backgroundImage:
                    (enterprise.software_type_id == 1) ?
                        `url('../assets/images/fondos/fondo2.jpg')` :
                        (enterprise.software_type_id == 2) ?
                        `url('../assets/images/fondos/fondo.png')`:
                        `url('../assets/images/fondos/fondo3.png')`

            }}
            >

                {/* <div className='fondo-register ' > */}
                <div className="container" style={{ width: "85%", paddingBottom: '10vh' }}>
                    <div className="row justify-content-center " style={{ paddingTop: '25vh' }}>
                        <div className=" col-xs-12 col-lg-5 col-xl-5 color-istmas">
                            <div className="card shadow p-3 mb-5 bg-white form-fondo">
                                <div className="card-header text-center">
                                    <h3 className='camping-letters text-center'>Comencemos!  </h3>
                                </div>
                                <div className="card-body">
                                    <form onSubmit={onSubmit} >

                                        <div className='row'>
                                            <div className="col-12 col-sm-12" style={{ paddingTop: '2vh' }}>
                                                <label className="form-label"><b>Correo</b></label>
                                                <input type="email" onChange={onInputChange} name='email' value={email} className="form-control" placeholder='Ej: usuario@gmail.com' aria-describedby="textHelp" required></input>
                                            </div>

                                        </div>

                                        <div className='row'>
                                            <div className="col-12 col-sm-12" style={{ paddingTop: '2vh' }}>
                                                <label className="form-label"><b>Contraseña</b></label>
                                                <input type="password" onChange={onInputChange} name='password' value={password} className="form-control" aria-describedby="textHelp" placeholder='********' required></input>
                                            </div>

                                        </div>
                                        <div>
                                        </div>

                                        <div className="text-center" style={{ paddingTop: '2vh' }}>
                                            {
                                                (errorMessage)
                                                    ?
                                                    <div className="alert alert-danger" role="alert">
                                                        {errorMessage}
                                                    </div>
                                                    :
                                                    <></>
                                            }

                                            <button type="submit" disabled={isAuthenticate} className="btn  btn btn-success " style={{ margin: "5px", width: "180px" }} > <b>Iniciar Sesión</b></button>
                                            <Link to="/">
                                                <button type="" className="btn  btn btn-dark " style={{ margin: "5px", width: "180px" }}  ><b>Cancelar </b></button>
                                            </Link>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer theme="colored" />
                {/* <FooterScreen/> */}
            </div>
        </>
    )
    // }
}


const fullScreenContainer = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100vw', // 100% of the viewport width
    height: '100vh', // 100% of the viewport height
    position: 'fixed', // Makes sure it covers the whole screen
    top: 0,
    left: 0,
    zIndex: 9999, // Ensures it is on top
    // backgroundColor:"Black"
};