import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom'
import { ApiUrl } from '../../services/ApiRest';
import { PreLoader } from '../initialsPages/PreLoader';

export const AdminSidebar = () => {

    const { displayName } = useSelector(state => state.auth);
    console.log(displayName);

    const [swType, setswType] = useState(0)

    const loadEntrpriseInfo = async () => {
        await axios.get(ApiUrl + 'enterprise')
            .then(resp => {
                const responseData = resp.data;
                console.log("enterprise PARA LISTO", responseData[0].software_type_id);
                setswType(responseData[0].software_type_id);
            })
            .catch(e => {
                console.log(e);
            })
        // if (swType != 0 || swType != 1 || swType != 2 || swType != 3)
        //     setswType(1);
    }

    useEffect(() => {
        loadEntrpriseInfo();
    }, [])

    // if (!swType) {
    //     return (<PreLoader/>)
    // }


    return (

        <nav className="sb-sidenav accordion sb-sidenav-dark" id="sidenavAccordion">
            <div className="sb-sidenav-menu">
                <div className="nav">
                    {/* {swType} */}
                    <div className="sb-sidenav-menu-heading">Core 1.0.5</div>
                    <Link className="nav-link" to="home">
                        <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt"></i></div>
                        INICIO
                    </Link>
                    <div className="sb-sidenav-menu-heading">Interface</div>

                    {
                        (swType === 1) ?
                            <a className="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#catalogo" aria-expanded="false" aria-controls="catalogo">
                                <div className="sb-nav-link-icon"><i className="fas fa-columns"></i></div>
                                Catálogo de Tours
                                <div className="sb-sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                            </a>
                            :
                            <></>
                    }

                    <div className="collapse" id="catalogo" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordion">
                        <nav className="sb-sidenav-menu-nested nav">
                            <Link className="nav-link" to="add-to-catalogue">Agregar a catálogo</Link>
                            <Link className="nav-link" to="catalogue-list">catálogo</Link>
                        </nav>
                    </div>

                    {
                        (swType === 1 || swType === 3) ?
                            <a className="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#monthly" aria-expanded="false" aria-controls="collapseLayouts">
                                <div className="sb-nav-link-icon"><i className="fas fa-mountain"></i></div>
                                Tours mensuales
                                <div className="sb-sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                            </a>
                            :
                            <></>
                    }


                    <div className="collapse" id="monthly" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordion">
                        <nav className="sb-sidenav-menu-nested nav">
                            <Link className="nav-link" to="addTour">Agregar Tour</Link>
                            <Link className="nav-link" to="monthly-tour-available">Tours Disponibles</Link>
                            <Link className="nav-link" to="monthly-tour-list">Todos los Tours</Link>
                            {/* <a className="nav-link" href="layout-sidenav-light.html">Listar Tours</a> */}
                        </nav>
                    </div>

                    {
                        (swType === 1) ?
                            <a className="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#passenger-tour" aria-expanded="false" aria-controls="collapseLayouts">
                                <div className="sb-nav-link-icon"><i className="fas fa-id-badge"></i></div>
                                Pasajeros en Tour
                                <div className="sb-sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                            </a>
                            :
                            <></>
                    }


                    <div className="collapse" id="passenger-tour" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordion">
                        <nav className="sb-sidenav-menu-nested nav">
                            <Link className="nav-link" to="passenger-available-tour">Tour con Listado</Link>
                            <Link className="nav-link" to="monthly-tour-list">Todos los Tours</Link>
                            <Link className="nav-link" to="monthly-tour-list">Tous Externos Solicitud</Link>
                            {/* <a className="nav-link" href="layout-sidenav-light.html">Listar Tours</a> */}
                        </nav>
                    </div>

                    {/* {
                        (swType === 1 || swType === 2) ?
                            <a className="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#solicitud" aria-expanded="false" aria-controls="gallery">
                                <div className="sb-nav-link-icon"><i className="fas fa-shopping-cart"></i></div>
                                Solicitud de productos
                                <div className="sb-sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                            </a>
                            :
                            <></>
                    }
                    <div className="collapse" id="solicitud" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordion">
                        <nav className="sb-sidenav-menu-nested nav">
                            <Link className="nav-link" to="crear-solicitud">Crear solicitud</Link>
                            <Link className="nav-link" to="Administrar-solicitud-productos">Administrar solicitudes</Link>
                            <Link className="nav-link" to="Historial-solicitudes">Historial de solicitudes</Link>

                        </nav>
                    </div> */}

                    {
                        (swType === 1 || swType === 2) ?
                            <a className="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#ventas" aria-expanded="false" aria-controls="ventas">
                                <div className="sb-nav-link-icon"><i className="far fa-money-bill-alt"></i></div>
                                Ventas
                                <div className="sb-sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                            </a>
                            :
                            <></>
                    }

                    <div className="collapse" id="ventas" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordion">
                        <nav className="sb-sidenav-menu-nested nav">
                            {/* <Link className="nav-link" to="add-equipment-sell">Agregar Producto</Link>
                            <Link className="nav-link" to="list-equipment-sell">Listar Productos</Link> */}
                            <Link className="nav-link" to="ventas-productos">Ventas</Link>
                            <Link className="nav-link" to="historial-ventas">Historial de ventas</Link>
                        </nav>
                    </div>



                    {
                        (swType === 1 || swType === 2) ?
                            <a className="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#inventory" aria-expanded="false" aria-controls="gallery">
                                <div className="sb-nav-link-icon"><i className="fas fa-warehouse"></i></div>
                                Inventario
                                <div className="sb-sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                            </a>
                            :
                            <></>
                    }
                    <div className="collapse" id="inventory" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordion">
                        <nav className="sb-sidenav-menu-nested nav">
                            {/* <Link className="nav-link" to="add-to-rent">Agregar Imagen</Link> */}
                            <Link className="nav-link" to="inventario">Inventario</Link>
                            <Link className="nav-link" to="productos">Productos</Link>
                            <Link className="nav-link" to="Categorias">Categorias</Link>
                            <Link className="nav-link" to="Proveedores">Proveedores</Link>
                            <Link className="nav-link" to="bodegas">Bodegas</Link>
                            <Link className="nav-link" to="porcomprar">Por Comprar</Link>
                            {
                                (swType === 1) ?
                                    <a className="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#pagesCollapseAuth" aria-expanded="false" aria-controls="pagesCollapseAuth">
                                        Solicitud de productos
                                        <div className="sb-sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                                    </a>
                                    :
                                    <></>
                            }
                            <div className="collapse" id="pagesCollapseAuth" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordionPages">
                                <nav className="sb-sidenav-menu-nested nav">
                                    {/* <a className="nav-link" href="login.html">Crear solicitud</a> */}
                                    <Link className="nav-link" to="crear-solicitud">Crear solicitud</Link>
                                    <Link className="nav-link" to="Administrar-solicitud-productos">Administrar solicitudes</Link>
                                    <Link className="nav-link" to="Historial-solicitudes">Historial de solicitudes</Link>
                                </nav>
                            </div>
                        </nav>
                    </div>


                    {
                        (swType === 1 || swType === 2) ?
                            <a className="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#credits" aria-expanded="false" aria-controls="gallery">
                                <div className="sb-nav-link-icon"><i className="fas fa-credit-card"></i></div>
                                Créditos
                                <div className="sb-sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                            </a>
                            :
                            <></>
                    }
                    <div className="collapse" id="credits" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordion">
                        <nav className="sb-sidenav-menu-nested nav">
                            {/* <Link className="nav-link" to="add-to-rent">Agregar Imagen</Link> */}
                            <Link className="nav-link" to="listar-creditos">Listar Créditos</Link>
                        </nav>
                    </div>


                    {
                        (swType === 1) ?
                            <a className="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#suscrip" aria-expanded="false" aria-controls="suscrip">
                                <div className="sb-nav-link-icon"><i className="fas fa-id-badge"></i></div>
                                Suscripciones
                                <div className="sb-sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                            </a>
                            :
                            <></>
                    }
                    <div className="collapse" id="suscrip" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordion">
                        <nav className="sb-sidenav-menu-nested nav">
                            <Link className="nav-link" to="listar-suscripciones">Listar suscriptores</Link>
                        </nav>
                    </div>



                    {
                        (swType === 1) ?
                            <a className="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#collapseLayouts" aria-expanded="false" aria-controls="collapseLayouts">
                                <div className="sb-nav-link-icon"><i className="fas fa-retweet"></i></div>
                                Alquiler y Ventas
                                <div className="sb-sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                            </a>
                            :
                            <></>
                    }

                    <div className="collapse" id="collapseLayouts" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordion">
                        <nav className="sb-sidenav-menu-nested nav">
                            <Link className="nav-link" to="add-to-rent">Agregar Producto Alquiler</Link>
                            <Link className="nav-link" to="list-to-rent">Listar Producto Alquiler</Link>
                            <Link className="nav-link" to="add-equipment-sell">Agregar Producto Venta</Link>
                            <Link className="nav-link" to="list-equipment-sell">Listar Producto Venta</Link>
                        </nav>
                    </div>


                    {
                        (swType === 1) ?
                            <a className="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#gallery" aria-expanded="false" aria-controls="gallery">
                                <div className="sb-nav-link-icon"><i className="fas fa-image"></i></div>
                                Galería
                                <div className="sb-sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                            </a>
                            :
                            <></>
                    }


                    <div className="collapse" id="gallery" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordion">
                        <nav className="sb-sidenav-menu-nested nav">
                            {/* <Link className="nav-link" to="add-to-rent">Agregar Imagen</Link> */}
                            <Link className="nav-link" to="list-gallery">Listar Imagenes</Link>
                        </nav>
                    </div>

                    {
                        (swType === 1) ?
                            <a className="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#collapsePages" aria-expanded="false" aria-controls="collapsePages">
                                <div className="sb-nav-link-icon"><i className="fas fa-home"></i></div>
                                Hospedaje
                                <div className="sb-sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                            </a>
                            :
                            <></>
                    }


                    {/* <div className="collapse" id="collapsePages" aria-labelledby="headingTwo" data-bs-parent="#sidenavAccordion">
                        <nav className="sb-sidenav-menu-nested nav accordion" id="sidenavAccordionPages">
                            <a className="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#pagesCollapseAuth" aria-expanded="false" aria-controls="pagesCollapseAuth">
                                Authentication
                                <div className="sb-sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                            </a>
                            <div className="collapse" id="pagesCollapseAuth" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordionPages">
                                <nav className="sb-sidenav-menu-nested nav">
                                    <a className="nav-link" href="login.html">Login</a>
                                    <a className="nav-link" href="register.html">Register</a>
                                    <a className="nav-link" href="password.html">Forgot Password</a>
                                </nav>
                            </div>
                            <a className="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#pagesCollapseError" aria-expanded="false" aria-controls="pagesCollapseError">
                                Error
                                <div className="sb-sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                            </a>
                            <div className="collapse" id="pagesCollapseError" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordionPages">
                                <nav className="sb-sidenav-menu-nested nav">
                                    <a className="nav-link" href="401.html">401 Page</a>
                                    <a className="nav-link" href="404.html">404 Page</a>
                                    <a className="nav-link" href="500.html">500 Page</a>
                                </nav>
                            </div>
                        </nav>
                    </div>


                    <div className="sb-sidenav-menu-heading">Addons</div>
                    <a className="nav-link" href="charts.html">
                        <div className="sb-nav-link-icon"><i className="fas fa-chart-area"></i></div>
                        Charts
                    </a>
                    <a className="nav-link" href="tables.html">
                        <div className="sb-nav-link-icon"><i className="fas fa-table"></i></div>
                        Tables
                    </a> */}
                </div>
            </div>
            <div className="sb-sidenav-footer">
                <div className="small">Usuario:</div>
                {displayName}
            </div>
        </nav>



    )
}
